<template>
  <div>
    <img class="img" src="@/assets/img/card/0926/slide1.png" alt="" usemap="#imagemap" />
    <map name="imagemap">
      <area class="imageMap" shape="rect" coords="0,300,300,300" href="http://naver.com" target="_blank" alt="test" />
    </map>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.img {
  width: 100%;
}
.imageMap {
  background: red;
}
</style>