<template>
  <div>
    <!-- <div>test Comp</div> -->
    <!-- <SearchTop></SearchTop> -->
    <!-- <UserList type="noCheck"></UserList> -->

    <!-- 날짜 -->
    <!-- <Tdate :calendar="calendar"></Tdate> -->

    <!-- <Combosubjcode :subj="subj"></Combosubjcode> -->

    <!-- <S3UploadTest /> -->

    <!-- <TiptapTest v-model="content" />

    <div class="content">
      <h3>Content</h3>
      <pre><code>{{ content }}</code></pre>
    </div> -->
    <!-- <vue-editor v-model="content"></vue-editor> -->

    <!-- <DateTodayComp /> -->

    <!-- <DateComp :calendar="propsCalendar" /> -->

    <!-- 차량번호 중복확인 -->
    <!-- <CarNumCheck /> -->

    <!-- spring login -->
    <!-- <springLogin /> -->

    <!-- 본인인증 테스트 -->
    <!-- <meDanal /> -->

    <ImageMap_test />
  </div>
</template>

<script>
import ImageMap_test from './ImageMap_test.vue';
// import SearchTop from '@/components/system/SearchTop.vue';
// import UserList from '@/components/system/UserList.vue';
// import Combosubjcode from '@/components/common/ComboSubjCode.vue';
// import Tdate from '@/components/common/DateComp.vue';
// import DateComp from '@/components/common/DateComp.vue';
// import DateTodayComp from '@/components/common/DateTodayComp.vue';
// import CarNumCheck from '@/views/carInfo/CarNumCheck.vue';
// import S3UploadTest from './S3UploadTest.vue';
// import TiptapTest from './Tiptap_Test.vue';
// import { VueEditor } from 'vue2-editor';
// import springLogin from './Spring_login.vue';
// import meDanal from './Me_danal.vue';

export default {
  components: {
    ImageMap_test,
    // springLogin,
    // meDanal,
    // Combosubjcode,
    // Tdate,
    // S3UploadTest,
    // VueEditor,
    // DateTodayComp,
    // DateComp,
    // CarNumCheck,
  },
  data() {
    return {
      subj: 'HE:2',
      calendar: '2021/07/20',
      // content: '<p>A Vue.js wrapper component for tiptap to use <code>v-model</code>.</p>',
      content: '<h1>Some initial content</h1>',
      // propsCalendar: this.$moment(),
      // propsCalendar: this.$moment(),
      propsCalendar: this.$moment('20220101'),
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/common';
</style>